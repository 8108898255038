.text-editor {
  .ql-toolbar {
    border-radius: 5px 5px 0 0;
    & > span {
      background: #80808010;
      padding: 2px;
      margin: 2px;
      border-radius: 5px;
      transition: background 0.4s;
      &:hover {
        background: #8080802f;
      }
    }
  }
  .ql-container {
    height: 300px;
    border-radius: 0 0 5px 5px;
  }
}
