.input__tooltip {
  position: relative;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 8px;
    bottom: 12px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.input__tooltip-space {
  position: relative;
  height: 64.5px;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 8px;
    bottom: 27px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.input__oneleft {
  position: relative;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 40px;
    bottom: 12.5px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.input__oneleft-space {
  position: relative;
  height: 64.5px;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 40px;
    bottom: 27.5px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.input__twoleft {
  position: relative;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 62px;
    bottom: 12.5px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.input__twoleft-space {
  position: relative;
  height: 64.5px;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 62px;
    bottom: 27.5px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}


.input__multiline {
  position: relative;
  height: 100%;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 8px;
    top: 17px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.input__multiline-space {
  position: relative;
  height: 118px;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 8px;
    top: 17px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

/* .input__checkbox {
  position: relative;
  height: 64.5px;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 65px;
    bottom: 21.5px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
} */

.tooltip {
  position: absolute;
  z-index: 1;
  top: 17px;
  font-size: 16px;
  right: 10px;
  opacity: 0;
  cursor: default;
}

.tooltip__oneleft {
  position: absolute;
  z-index: 1;
  top: 17px;
  font-size: 16px;
  right: 42px;
  opacity: 0;
  cursor: default;
}

.tooltip__twoleft {
  position: absolute;
  z-index: 1;
  top: 17px;
  font-size: 16px;
  right: 64px;
  opacity: 0;
  cursor: default;
}

/*
.tooltip__checkbox {
  position: absolute;
  z-index: 1;
  font-size: 16px;
  top: 23px;
  right: 67px;
  opacity: 0;
  cursor: default;
} */

.tooltip__spacing > div > input {
  padding-right: 34px;
}
.tooltip__spacing > div > textarea {
  padding-right: 15px;
}

/* .input__helper {
  position: relative;
  height: 64.5px;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 8px;
    bottom: 27px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.input__multiline{
  position: relative;
  height: 100%;
  &::after {
    content: '?';
    text-align: center;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    background: #bdc3c7;
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    color: white;
    font-weight: bold;
    opacity: 0;
    right: 8px;
    top: 17px;
    width: 0;
    height: 20px;
    transition: all 0.7s ease;
  }
  &:hover::after {
    width: 20px;
    opacity: 1;
  }
}

.helper__tooltip {
  position: absolute;
  z-index: 1;
  top: 17px;
  font-size: 16px;
  right: 10px;
  opacity: 0;
  cursor: default;
}

.input__less > div > input {
  padding-right: 36px;
} */
