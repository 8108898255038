section {
  margin-top: 10%;
}

.error__content {
  text-align: center;
  .error__title {
    font-size: 2em;
    margin-bottom: 0;
    font-weight: 300;
  }
  .error__icon {
    transform: translateY(6px);
    font-size: 1.9rem;
    color: #FFC107;
  }
  .error__info {
    text-align: center;
    & p {
      display: flex;
      justify-content: center;
      margin: 8px 0 10px 12px;
      opacity: .7;
    }
    & a {
     /*  color: #0063CF; */
     color: #023c5e;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
