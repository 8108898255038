.more-details {
  border: none;
}
.more-details__table {
  padding: 10px;
}

.more-details:hover {
  background: inherit !important;
}
