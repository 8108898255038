$green: #29b665;
$darkGreen: #208c4e;
$white: #f5f6fa;

.button__green {
  margin: 8px;
  background: $green !important;
  box-shadow: none;
  width: 105px;
  color: $white !important;
  &:hover {
    background: $darkGreen !important;
  }
}

.button__green--small {
  margin: 8px;
  background: $green !important;
  box-shadow: none;
  color: $white !important;
  &:hover {
    background: $darkGreen !important;
  }
}

.button__default {
  margin: 8px;
  box-shadow: none;
  width: 105px;
}

.button__default--small {
  margin: 8px;
  box-shadow: none;
}

.button__margin-right {
  margin-right: 6px;
}
