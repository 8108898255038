.tablecell__ordered {
  cursor: pointer;
}
.tablecell__title {
  display: flex;
}
.tablecell__title--center {
  display: flex;
  justify-content: center;
}
.tablecell__title--icon {
  height: 24px;
  & > svg {
    width: 1.25rem;
  }
}
.icon__unfoldmore {
  color: #2425257e;
}
