$black: #212121;

.action__button--icon{
  color: $black;
  margin-right: 16px;
}

.action__button--wpp{
  display: flex;
  align-items: center;
}
