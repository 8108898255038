$blue: #023c5e;
$lightBlue: #0063cf22;

.title__dropdown.nodrop {
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 110px;
}

.title__dropdown--selected {
  color: $blue;
  background: $lightBlue;
  &:hover {
    background: $lightBlue;
  }
}

.subtitle__dropdown--downlevel {
  margin-left: 12px;
}
