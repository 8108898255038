.title__dropdown{
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
}

#menu-list-grow{
  padding: 0;
  min-width: 200px;
}
