.upload__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding-bottom: 6px;
  width: 100%;

  .label__upload {
    background-color: #ffffff;
    border: 1px solid #ababab;
    font-size: 13px;
    border-radius: 5px;
    color: #757575;
    padding: 8px 12px;
    cursor: pointer;
    opacity: 0.85;
    width: inherit;
  }

  .name-label__upload {
    position: absolute;
    font-weight: 100;
    bottom: 20px;
    padding: 0 4px;
    font-size: 13px;
    color: #7e7e7e;
    background: #FFFFFF;
    left: 10px;

  }

  .input__upload {
    display: none;
  }
}
