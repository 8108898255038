.edit-include__buttons {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 9.2px !important;
  & button {
    padding: 4px 10px;
    border: none;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
  .edit-include__buttons--icon {
    color: #2d3436;
    width: 18px;
  }
}

.include__button {
  margin-right: 10px;
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    cursor: initial;
    filter: initial;
  }
}

.edit__button {
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    cursor: initial;
    filter: initial;
  }
}

.edit-include__buttons.in-section {
  height: 40px;
}
