.header__title {
  padding-bottom: 12px;
}

.header__tools {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1500px) {
  .header__search {
    padding-right: 5px;
  }
}

@media (max-width: 1380px) {
  .header__search {
    flex-basis: 90% !important;
  }
}

@media (max-width: 1180px) {
  .header__search {
    flex-basis: 88.5% !important;
  }
}

@media (max-width: 960px) {
  .header__tools {
    display: block;
  }
  .header__search {
    flex-basis: 91.666667% !important;
  }
  .text__align--center > button {
    width: 25%;
  }
}
